import * as React from "react";
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import CardWebDesignImage from '../../images/card-web-design-image.jpg';
import CardWebDesignImage3 from '../../images/card-web-design-image3.jpg';
import SEO from '../../components/Seo';

const WebAppDevelopment = () => {

    return(
        <Layout>
            <SEO title="Web App Development"/>
            <section>
                <div className="pt-10 bg-blue-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
                    <div className="mx-auto max-w-7xl lg:px-8">
                        <div className="lg:grid lg:grid-cols-1 lg:gap-8">
                            <div className="mx-auto px-4 sm:px-6 sm:text-center lg:px-0 lg:text-center lg:flex lg:items-center">
                                <div className="py-8 sm:py-8 lg:py-24">
                                    <span className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                        SERVICES
                                    </span>
                                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                         <span className="block">Web Application Development</span> 
                                         <span className="block mt-0 lg:mt-2">For Healthcare</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Content */}
            <section>
                <div className="relative bg-white py-8 lg:py-16">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                        <div className="relative mx-auto px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                            <div className="pt-12 sm:pt-0 lg:pt-12">
                                <h2 className="text-3xl text-blue-900 font-extrabold tracking-tight sm:text-4xl">
                                    A Custom Software Solution For Your Particular Use Case
                                </h2>
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-2xl">
                                    When you can't find an existing solution or have a very particular use case, we can design & develop custom web applications specifically for your needs.
                                    </p>
                                    <p className="text-xl leading-7">
                                    Coming Soon.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="relative mt-10 sm:py-16 lg:py-0">
                            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:left-72">
                                <div className="absolute inset-y-0 left-1/2 w-full bg-gray-50 rounded-r-3xl lg:left-72"></div>
                                <svg className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
                                <defs>
                                    <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                                </svg>
                            </div>
                            
                            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                <div className="relative pt-64 pb-48 rounded-2xl shadow-xl overflow-hidden">
                                    <img className="absolute inset-0 h-full w-full object-cover" src={CardWebDesignImage} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* VIEW WORK SECTION */}
           {/* <section>
                <div className="pb-16 bg-gray-50 lg:pb-0 lg:z-10 lg:relative">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
                    <div className="relative lg:-my-8">
                        <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"></div>
                        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                        <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                            <img className="object-cover lg:h-full lg:w-full" src={CardWebDesignImage} alt=""/>
                        </div>
                        </div>
                    </div>
                    <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                        <blockquote>
                            <div>
                          
                            <p className="mt-6 text-3xl font-medium text-blue-900">
                                We ensure that your website is fast, mobile optimized, accessible, user friendly, sercure, and aesthetically pleasing.
                            </p>
                            </div>
                         
                        </blockquote>
                        </div>
                    </div>
                    </div>
                </div>
           </section> */}

            {/* CTA */}
            <section>    
                <div className="bg-blue-700 mt-10">
                    <div className="max-w-4xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                        <span className="block">It's time to upgrade your digital presence.</span>
                    </h2>
                    <Link to="/contact-us" className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-900 bg-white hover:bg-indigo-50 sm:w-auto">
                        Contact Us For a No Obligation Consult
                    </Link>
                    </div>
                </div>
            </section>
            
        </Layout>
    )
}

export default WebAppDevelopment;